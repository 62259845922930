import React from "react";
import type { PageContext } from "vike/types";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
// import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PageContextProvider } from "./usePageContext";
import { theme } from "../utils/Theme";

import "@mantine/core/styles/global.css";
import "@mantine/core/styles/AppShell.css";
import "@mantine/core/styles/UnstyledButton.css";
import "@mantine/core/styles/Button.css";
import "@mantine/core/styles/Title.css";
import "@mantine/core/styles/Text.css";
import "@mantine/core/styles/Notification.css";
import "@mantine/notifications/styles.css";
import "@mantine/core/styles/Loader.css";
import { Provider, getDefaultStore } from "jotai";
import { GoogleOAuthProvider } from "@react-oauth/google";
const store = getDefaultStore();

export { PageShell };

const queryClient = new QueryClient();

function PageShell({
  pageContext,
  children,
}: {
  pageContext: PageContext;
  children: React.ReactNode;
}) {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <GoogleOAuthProvider clientId="483701426495-8ddgh0fqu46i3qit8rbu19b4l5e291kv.apps.googleusercontent.com">
            <MantineProvider
              theme={theme}
              forceColorScheme="dark"
              defaultColorScheme="dark"
              classNamesPrefix="imggen-"
            >
              <Notifications position="top-right" />
              <PageContextProvider pageContext={pageContext}>
                {children}
              </PageContextProvider>
            </MantineProvider>
          </GoogleOAuthProvider>
        </QueryClientProvider>
      </Provider>
    </React.StrictMode>
  );
}
