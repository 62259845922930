import { atomWithStorage } from "jotai/utils";

export const isAuthenticatedAtom = atomWithStorage(
  "is_authenticated",
  false
  //   undefined,
  //   {
  //     getOnInit: true,
  //   }
);
