// https://vike.dev/onRenderClient
export { onRenderClient };

import "./css/index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { PageShell } from "./PageShell";
import { getPageTitle } from "./getPageTitle";
import type { OnRenderClientAsync } from "vike/types";
import { getDefaultStore } from "jotai";
import { authenticatedUserAtom } from "../hooks/authenticatedUserAtom";
import { isAuthenticatedAtom } from "../hooks/isAuthenticatedAtom";
import RouteMiddleware from "../middleware/RouteMiddleware";
const store = getDefaultStore();

let root: ReactDOM.Root;
const userIntialData: any = JSON.parse(localStorage.getItem("user") as string);
const isSignedInIntialData: any = JSON.parse(
  localStorage.getItem("is_authenticated") as string
);

const onRenderClient: OnRenderClientAsync = async (
  pageContext
): ReturnType<OnRenderClientAsync> => {
  const { Page, pageProps } = pageContext;
  const user = store.get(authenticatedUserAtom);
  const isSignedIn = store.get(isAuthenticatedAtom);

  const page = (
    <PageShell
      pageContext={{
        ...pageContext,
        user: userIntialData || user,
        isSignedIn: isSignedInIntialData || isSignedIn,
      }}
    >
      {/* <RouteMiddleware
        user={userIntialData}
        pathname={pageContext.urlPathname}
        isAuthenticated={isSignedInIntialData}
      > */}
      <Page {...pageProps} />
      {/* </RouteMiddleware> */}
    </PageShell>
  );
  const container = document.getElementById("page-view")!;
  if (pageContext.isHydration) {
    root = ReactDOM.hydrateRoot(container, page);
  } else {
    if (!root) {
      root = ReactDOM.createRoot(container);
    }
    root.render(page);
  }
  document.title = getPageTitle(pageContext);
};
