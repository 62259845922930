import {
  AppShell,
  Button,
  Select,
  createTheme,
  // defaultVariantColorsResolver,
  rem,
} from "@mantine/core";

export const theme = createTheme({
  autoContrast: true,
  primaryColor: "primary",
  colors: {
    primary: [
      "#FAFFF3",
      "#f4ffe3",
      "#ecffcc",
      "#d9ff9b",
      "#c4ff64",
      "#b3ff38",
      "#a7ff1c",
      "#a1ff09",
      "#8be300",
      "#7ac900",
      "#66ae00",
    ],
  },
  headings: {
    fontWeight: "700",
    sizes: {
      h1: {
        fontSize: rem(48),
        fontWeight: "800",
        lineHeight: "normal",
      },
      h2: {
        fontSize: rem(38),
        fontWeight: "800",
        lineHeight: "normal",
      },
    },
  },

  components: {
    Button: Button.extend({
      defaultProps: {
        size: "md",
        variant: "filled",
        loaderProps: {
          type: "dots",
        },
        style: { fontSize: 14, fontWeight: 500 },
      },
    }),
    AppShell: AppShell.Main.extend({
      defaultProps: {},
    }),
    Select: Select.extend({
      defaultProps: {
        checkIconPosition: "right",
        allowDeselect: false,
      },
      styles: {
        label: {
          marginBottom: 6,
        },
      },
    }),
  },
  // variantColorResolver: (input) => {
  //   const defaultResolvedColors = defaultVariantColorsResolver(input);

  //   if (input.variant === "danger") {
  //     return {
  //       background: "var(--mantine-color-red-8)",
  //       hover: "var(--mantine-color-red-9)",
  //       color: "var(--mantine-color-white)",
  //       border: "none",
  //     };
  //   }

  //   return defaultResolvedColors;
  // },
});
